@font-face {
  font-family: 'Silver Spoon';
  src: url('../fonts/silverspoon_v0.2-webfont.eot');
  src: url('../fonts/silverspoon_v0.2-webfont.eot?#iefix') format('embedded-opentype'),
       url('../fonts/silverspoon_v0.2-webfont.woff2') format('woff2'),
       url('../fonts/silverspoon_v0.2-webfont.woff') format('woff'),
       url('../fonts/silverspoon_v0.2-webfont.ttf') format('truetype'),
       url('../fonts/silverspoon_v0.2-webfont.svg#silverspoonregular') format('svg');
  font-weight: normal;
  font-style: normal;
}