// Colors
$pink: #FAA5DE;
$yellow: #F8E71C;

// Typography
$font-sans: 'Helvetica Neue', Helvetica, sans-serif;
$font-serif: 'Silver Spoon', serif;

// Measurements
$content-width: 98rem;

// Responsive
// For some reason, ems of both height/width weren't working in IE
$desktop-width: 640px;
