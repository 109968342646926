section.theater {
  font-family: $font-serif;
  font-size: 2rem;
  font-weight: normal;
  text-align: center;
  background: $yellow; 
  border-width: 2px 0;
  border-style: solid;
  border-color: black;

  @include desktop {
    font-size: 3.4rem; 
  }

  .content {
    margin-bottom: 3rem;

    @include desktop {
      margin-bottom: 4.5rem; 
    }

    img {
      width: 100%;
      max-width: 78rem;
      margin-top: 4rem;
      margin-bottom: 4rem;
    }
  }

  .buy-theater {
    display: inline-block;
    font-family: $font-sans;
    font-weight: bold;
    font-size: 1.8rem;
    color: black;
    background: white;
    text-decoration: none;
    border: 2px solid black;
    border-radius: 10px;
    padding: 1rem 2rem;
    white-space: nowrap;

    @include desktop {
      font-size: 3rem;
    }

    &:hover {
      background-color: $yellow;
    }
  }
}