header {
  padding: 2.8rem 2rem 2.4rem;
  background: black;
  text-align: center;
  z-index: 100;

  @include desktop {
    position: fixed;
    top: 0;
    width: 100%;
    padding-right: 4rem;
    padding-left: 4rem;
    text-align: left;
  }

  .logo {
    display: inline-block;
    position: relative;
    vertical-align: top;
    line-height: 0;
    background: #FDEDC1;

    img {
      width: 100%;
      max-width: 50rem;
    }

    .animated-gradient {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      width: 31%;
      z-index: -1;
    }
  }

  .buy-pride {
    display: block;
    position: fixed;
    bottom: 5rem;
    right: 2rem;
    width: 9rem;
    height: 9rem;
    box-shadow: -3px 30px 25px rgba(0, 0, 0, 0.3);
    border-radius: 120px;
    animation: scale-up-center 2s ease-in-out infinite alternate-reverse both;
    z-index: 9999999;

    &:hover {
      &,
      #button,
      #flag {
        animation-play-state: paused;
      }
    }

    &.cart-active {
      display: none;
    }

    @include desktop {
      position: absolute;
      top: 2.5rem;
      right: 9rem;
      bottom: auto;
    }

    #button {
      position: absolute;
      right: -5px;
      top: 0px;
      height: 130px;
      width: 130px;
      animation: rotate 30s linear infinite;
      transform-origin: 50% 50% 0;
      z-index: 100;
    }

    #flag {
      position: absolute;
      top: 3px;
      right: 0;
      height: 120px;
      width: 120px;
      opacity: 100%;
      transition: opacity .25s ease-in-out;
      animation: scale-up-center 2s ease-in-out infinite alternate-reverse both;
      z-index: 300;

      &:hover {
          cursor: pointer;
          opacity: .5;
      }
    }
  }
}