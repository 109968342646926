section.buy {
  position: relative;
  padding: 0;

  @include desktop {
    border-bottom: 2px solid black;
  }

  h2 {
    position: absolute;
    top: -3rem;
    left: 50%;
    display: inline-block;
    padding: 1rem 2rem;
    font-family: $font-serif;
    font-size: 3rem;
    font-weight: normal;
    white-space: nowrap;
    background: white;
    border: 2px solid black;
    transform: translateX(-50%);
    z-index: 2;
  }

  h3 {
    font-weight: normal;
    margin-bottom: 3rem;
  }

  .product {
    position: relative;
    font-family: $font-serif;
    text-align: center;
    padding: 6rem 0;
    border-color: black;
    border-style: solid;
    border-width: 0 0 2px 0;

    @include desktop {
      float: left;
      width: 50%;
      padding: 12rem 0;
      border-width: 0;
    }

    h3 {
      font-size: 4rem;

      @include desktop {
        font-size: 5rem;
      }

      @media screen and (min-width: 960px) {
        font-size: 6rem;
      }
    }

    > div:first-of-type {
      position: relative;
      z-index: 3;
    }

    &:before {
      $t: transparent;
      $w1: rgba(255,255,255,0.2);
      $w2: rgba(255,255,255,0.4);

      display: block;
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      width: 100%;

      background-image: linear-gradient(120deg, 
        $t 0%, $t 45%, 
        $w1 45%, $w1 49%, 
        $w2 49%, $w2 51%, 
        $w1 51%, $w1 55%, 
        $t 55%, $t 100%
      );
      background-position: right top;
      background-size: 800% 100%;
      animation-duration: 6s;
      animation-fill-mode: both;
      animation-iteration-count: infinite;
      animation-name: backgroundSlide;
      animation-timing-function: linear;
      z-index: 0;
    }

    &.product-glitter {
      border-color: black;
      border-style: solid;
      border-width: 0 0 2px;

      @include desktop {
        border-width: 0 2px 0 0;
      }

      &:after {
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        content: '';
        background: url('../images/glitter.gif');
      }
    }
  }
}