*, *:before, *:after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
  box-sizing: border-box;
}

body {
  font-size: 1.6rem;
  font-family: $font-sans;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include desktop {
    font-size: 2.4rem;
    padding-top: 8rem;
  }
}

a {
  color: inherit;
}

p {
  line-height: 1.5em;
}

a,
.btn,
.foreground {
  position: relative;
  z-index: 1;
}

.hidden {
  display: none;
}

section,
footer {
  padding: 6rem 0;

  @include desktop {
    padding: 12rem 0;
  }

  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

.container {
  margin: 0 auto;
  padding: 0 3rem;
  width: 100%;
  max-width: $content-width;
}

.widget-wrap {
  display: inline-block;
  background: rgba(0,0,0,0.5);
  border-radius: 10px;
  width: 288px;
  height: 60px;
  line-height: 60px;
}