.rainbow-gradient {
  background-color: $pink;
  background-image: linear-gradient(45deg, #FAA5DE, #FAA5DE 15%, #FDEDC1, #ADFFD9, #52E9F9, #92E9F9);
}

.animated-gradient {
  position: relative;
  overflow: hidden;
}

.animated-gradient-rotating {
  @extend .rainbow-gradient;
  position: absolute;
  top: 50%;
  left: 50%;
  box-sizing: content-box;
  transform: translateX(-50%) translateY(-50%) rotate(0deg);
  animation: rotateBg 10s linear infinite;
  z-index: -1;
}
