/**
 * Background Slide
 */
@-webkit-keyframes backgroundSlide {
  0% {
    background-position: right top, right top; }
  75% {
    background-position: left top, right top; }
  100% {
    background-position: left top, right top; } }

@keyframes backgroundSlide {
  0% {
    background-position: right top, right top; }
  75% {
    background-position: left top, right top; }
  100% {
    background-position: left top, right top; } }

/**
 * Scale Up Center
 */
@-webkit-keyframes scale-up-center {
  0%   { -webkit-transform: scale(1); }
  100% { -webkit-transform: scale(1.07); }
}

@keyframes scale-up-center {
  0%   { transform: scale(1); }
  100% { transform: scale(1.07); }
}

/**
 * Rotate
 */
@-webkit-keyframes rotate {
    0%   { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}

@keyframes rotate {
    0%   { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/**
 * Rotate BG
 */
@-webkit-keyframes rotateBg {
  0% { -webkit-transform: translateX(-50%) translateY(-50%) rotate(0deg); }
  100% { -webkit-transform: translateX(-50%) translateY(-50%) rotate(360deg); }
}

@keyframes rotateBg {
  0% { transform: translateX(-50%) translateY(-50%) rotate(0deg); }
  100% { transform: translateX(-50%) translateY(-50%) rotate(360deg); }
}

/**
 * Characters
 */
@-webkit-keyframes characters {
  0% { -webkit-transform: translateX(-50%); }
  100% { -webkit-transform: translateX(0%); }
}

@keyframes characters {
  0% { transform: translateX(-50%); }
  100% { transform: translateX(0%); }
}

/**
 * Buildings
 */

@-webkit-keyframes buildings {
  0% { background-position: 0 top; }
  100% { background-position: 100% top; }
}

@keyframes buildings {
  0% { background-position: 0 top; }
  100% { background-position: 100% top; }
}
