section.faq {
  background: white;

  a:hover {
    text-decoration: none;
  }

  h2 {
    font-family: $font-serif;
    font-size: 3rem;
    font-weight: normal;
    text-align: center;
    margin-bottom: 3rem;

    @include desktop {
      font-size: 6rem;
      margin-bottom: 6rem;
    }
  }

  ul {
    list-style: none;
    font-size: 1.6rem;

    @include desktop {
      font-size: 2.4rem;
    }
  }

  li {
    background: url(../images/star.svg) no-repeat left 0.5rem;
    padding-left: 4rem;
    margin-bottom: 3rem;

    > div {
      margin-bottom: 0.5rem;
    }

    .question {
      font-family: $font-serif;
      font-weight: normal;
      line-height: 1.5em;
      border-bottom: 4px solid;
    }

    &:nth-child(5n+1) .question { border-color: #FAA5DE; }
    &:nth-child(5n+2) .question { border-color: #FDEDC1; }
    &:nth-child(5n+3) .question { border-color: #ADFFD9; }
    &:nth-child(5n+4) .question { border-color: #92E9F9; }
    &:nth-child(5n+5) .question { border-color: #B3C6FF; }
  }
}