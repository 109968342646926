.modal {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999999;

  &:before {
    @extend .rainbow-gradient;
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.5;
    z-index: 1;
  }

  > div {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    margin: 0 auto;
    max-width: 62.5rem;
    width: 95%;
    border: 2px solid black;
    border-radius: 10px;
    z-index: 2;
    overflow: hidden;
  }

  .header {
    padding: 1rem;
    font-family: $font-serif;
    font-size: 1.6rem;
    border-bottom: 2px solid black;

    @include desktop {
      font-size: 2.8rem;
    }
  }

  .body {
    padding: 2rem;
    font-size: 1.4rem;
    background-color: white;

    @include desktop {
      font-size: 2.2rem; 
    }
  }

  .close {
    position: absolute;
    top: 0;
    right: 0;
    height: 3.9rem;
    width: 3.9rem;
    font-size: 2.5rem;
    line-height: 3.4rem;
    text-align: center;
    font-family: $font-sans;
    font-weight: normal;
    border-left: 2px solid black;
    text-decoration: none;
    display: block;

    @include desktop {
      height: 5.3rem;
      width: 5.3rem;
      font-size: 4rem;
      line-height: 4.3rem;  
    }
  }
}