#paradeContainer {
  position: relative;
  width: 100%;
  height: 64rem;
  background: white;
  overflow-y: hidden;
  z-index: 1;

  *, *:before, *:after {
    box-sizing: content-box;
  }
}
