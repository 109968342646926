footer {
  text-align: center;
  font-size: 1.4rem;
  padding-bottom: 9rem;

  @include desktop {
    font-size: 1.8rem;
    padding-bottom: 16rem;
  }

  &.cart-active {
    padding-bottom: 20rem;
  }

  a {
    font-family: $font-serif;
    white-space: nowrap;

    &:hover {
      text-decoration: none;
    }
  }

  p {
    margin-bottom: 2rem;
  }

  .btn {
    position: relative;
    display: inline-block;
    margin-top: 6rem;

    &:before {
      position: absolute;
      bottom: 100%;
      left: 5rem;
      display: inline-block;
      content: '';
      width: 5.3rem;
      height: 5.2rem;
      background: url(../images/flag.gif) center center no-repeat;
      background-size: auto 100%;
    }

    &:hover {
      background: transparent;
    }
  }
}