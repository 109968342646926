.btn {
  display: inline-block;
  color: black;
  font-family: $font-serif;
  font-weight: normal;
  text-decoration: none;
  padding: 0.5rem 2rem;
  background-color: white;
  border: 2px solid black;
  border-radius: 10px;
  white-space: nowrap;

  &.btn-gradient {
    border-color: white;
    background-image: linear-gradient(45deg, #FAA5DE, #FDEDC1, #ADFFD9, #52E9F9, #92E9F9);

    .animated-gradient-rotating {
      opacity: 1;
      transition: opacity 0.2s ease-in;
    }

    &:hover {
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      .animated-gradient-rotating {
        opacity: 0;
      }
    }
  }
}
