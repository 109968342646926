section.intro {
  background: black;
  color: white;
  font-family: $font-serif;
  font-weight: normal;
  overflow: hidden;

  @include desktop {
    padding-top: 12rem;
    padding-bottom: 6rem;
  }

  .pack {
    position: relative;
    display: block;
    width: 16rem;
    margin: 0 auto 3rem;
    transform: rotate(-2deg);

    @include desktop {
      float: left;
      margin-top: -2rem;
      margin-right: 9rem;
      width: 30rem;
    }

    &.show-stickers {
      cursor: pointer;
      z-index: 1;

      .sticker {
        opacity: 1;

        &:nth-of-type(2) {
          top: 1rem;
          right: -1rem;
          transform: rotate(-15deg) scale(1);
        }

        &:nth-of-type(3) {
          top: 28rem;
          left: -2rem;
          transform: rotate(-15deg) scale(1);
        }

        &:nth-of-type(4) {
          top: -2.5rem;
          left: -2rem;
          transform: rotate(10deg) scale(1);
        }

        &:nth-of-type(5) {
          bottom: 1rem;
          left: 12rem;
          transform: rotate(12deg) scale(1);
        }

        &:nth-of-type(6) {
          top: 25rem;
          right: -2rem;
          transform: rotate(18deg) scale(1);
        }
      }
    }

    &.hide-stickers .sticker {
      display: none !important;
    }

    .sticker {
      display: none;
      position: absolute;
      width: 12rem;
      opacity: 0;
      transform: rotate(0) scale(0.5);
      transition: opacity 0s linear, transform .1s;
      z-index: 2;

      @include desktop {
        display: block;
      }
    }
  }

  .content {
    position: relative;
    margin-bottom: 3rem;

    @include desktop {
      font-size: 2.8rem;
    }

    span.rainbow {
      @extend .rainbow-gradient;
      background-color: #FAA5DE;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .show-me {
    display: none;

    @include desktop {
      display: block;
    }
  }

  .sample {
    display: none;
    position: absolute;
    width: 45rem;
    top: 5rem;
    right: -9rem;

    @include desktop {
      display: block;
    }

    img {
      position: absolute;
      width: 24rem;
      opacity: 0;
      transform: translateX(100%);
      transition: all 0.21s cubic-bezier(0.95, 0.05, 0.795, 0.035);
      cursor: pointer;
      transition-delay: 0.15s;
      z-index: 2;

      &:nth-child(2) {
        top: 5rem;
        left: 10rem;
        transition-delay: 0.10s;
        z-index: 3;
      }

      &:nth-child(3) {
        top: -5rem;
        left: 20rem;
        transition-delay: 0.05s;
      }

      &.selected {
        z-index: 4;
      }
    }

    &.show img {
      opacity: 1;
      transform: translateX(0) rotate(-18deg);
      transition: all 0.60s cubic-bezier(0.19, 1, 0.22, 1);
      transition-delay: 0;

      &:nth-child(2) {
        transform: translateX(0) rotate(-8deg);
        transition-delay: 0.05s;
      }

      &:nth-child(3) {
        transform: translateX(0) rotate(-12deg);
        transition-delay: 0.10s;
      }
    }
  }
}